import React from "react";
import type { NextPage } from "next";
import Head from "next/head";
import { Layout } from "../lib/Components/Layout/LayoutHome";
import HomePage from "./HomePage";

const Home: NextPage = () => {
  return (
    <div style={{ height: "100%", width: "100%" }}>
      <Head>
        <title>VCA - Bestel hier je VCA-examen en VCA E-learning</title>
        <meta name="description" content="Centraal bureau examens" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      <Layout
        isHomePage={true}
        title={"VCA - Bestel hier je VCA-examen en VCA E-learning"}
      >
        <HomePage />
      </Layout>
    </div>
  );
};

export default Home;
