import React from "react";
import { Global } from "@emotion/react";
import { globalCSS } from "../../theme/global.styles";
import { resetCSS } from "../../theme/reset.styles";
import Box from "@mui/material/Box";
import {
  Divider,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { StyledMain } from "./Layout.styles";
import { Header } from "../Layout/Header";
import { useTheme } from "../../theme/use-theme";
import Stack from "@mui/material/Stack";
import { IdealLogo } from "../../../assets/SVG/IDEALLogo";
import { useWindowSize } from "../../hooks";
import Email from "@mui/icons-material/Email";
import dayjs from "dayjs";
import { Phone } from "@mui/icons-material";

interface LayoutProps {
  children?: React.ReactNode;
  title: string;
  isHomePage?: boolean;
}

export const Layout = ({ children = true, title, isHomePage }: LayoutProps) => {
  const { height, width } = useWindowSize();
  const theme = useTheme();
  const smallDevice = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Stack id="HomeLayout" minHeight="100%" width="100%">
      <Global styles={[resetCSS, globalCSS]} />
      <Header isHomePage={isHomePage} />
      <Box
        id="HomeLayoutBox"
        display="flex"
        justifyContent="center"
        // flexGrow={1}
        minHeight={
          (height ?? 600) -
          theme.dimensions.heightFooter1 -
          theme.dimensions.heightFooter2 -
          3
        }
      >
        <StyledMain
          style={{
            alignItems: "center",
            display: "flex",
          }}
          theme={theme}
        >
          {children}
        </StyledMain>
      </Box>
      <Box
        id="Footer"
        height={
          smallDevice
            ? theme.dimensions.heightAppBar + 40
            : theme.dimensions.heightAppBar
        }
        width={"100%"}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
        }}
        bgcolor={theme.appColors.primary}
      >
        <Stack
          id="innerFooter"
          direction="row"
          justifyContent="space-between"
          maxWidth={1600}
          // minWidth={600}
          width="85%"
        >
          <Box>
            <Stack
              direction={smallDevice ? "column" : "row"}
              spacing={1}
              alignItems={smallDevice ? undefined : "center"}
            >
              {/* <Link
                href="https://vcaexamen.nl"
                variant="inherit"
                underline="hover"
                color={theme.appColors.tertiaryLight}
                sx={{
                  fontSize: 22,
                }}
              >
                VCAexamen.nl
              </Link> */}
            </Stack>
            <Typography color={theme.appColors.white}>VCAexamen.nl</Typography>
            <Typography color={theme.appColors.white}>Arkeneel 25</Typography>
            <Typography color={theme.appColors.white}>
              3905 NS Veenendaal
            </Typography>
            {/* {smallDevice && ( */}
            <IconButton
              sx={{ padding: 0 }}
              size="large"
              onClick={(e) => {
                //@ts-ignore
                window.location = `mailto:  info@vcaexamen.nl?subject=Bericht vanaf de website VCAexamen.nl`;
                e.preventDefault();
              }}
              style={{ backgroundColor: "transparent" }}
              color="inherit"
            >
              <Email
                sx={{
                  color: theme.appColors.white,
                  height: 20,
                  marginRight: 1,
                }}
              />

              <Typography
                color={theme.appColors.white}
                fontSize={theme.dimensions.headerFontSize}
              >
                info@vcaexamen.nl
              </Typography>
            </IconButton>
            <IconButton
              onClick={(e) => {
                //@ts-ignore
                window.location = `tel:31850705318`;
                e.preventDefault();
              }}
              size="small"
              style={{ backgroundColor: "transparent" }}
              color="inherit"
            >
              <Phone
                sx={{
                  color: theme.appColors.white,
                  height: 20,
                }}
              />
              {!smallDevice && (
                <Typography
                  color={theme.appColors.white}
                  fontSize={theme.dimensions.headerFontSize}
                >
                  085 - 0705318
                </Typography>
              )}
            </IconButton>
            {/* )} */}
          </Box>
          <IdealLogo height="67px" width="80px" />
        </Stack>
      </Box>
      <Box
        id="Footer2"
        width={"100%"}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Stack
          id="innerFooter2"
          direction={{ md: "row", xs: "column" }}
          maxWidth={1600}
          width="85%"
          paddingTop={2}
          paddingBottom={2}
          paddingRight={2}
        >
          <Link
            href="https://vcaexamen.nl"
            variant="inherit"
            underline="hover"
            color={theme.appColors.bodyColorGrey}
            lineHeight="1.5"
            paddingRight={2}
            fontSize={theme.dimensions.headerFontSize}
          >
            © {dayjs().format("YYYY")} VCAexamen.nl
          </Link>
          <Divider
            orientation={smallDevice ? "horizontal" : "vertical"}
            flexItem
          />

          <Link
            href="https://vcaexamen.nl/informatie/algemene-voorwaarden/"
            variant="inherit"
            underline="hover"
            color={theme.appColors.bodyColorGrey}
            lineHeight="1.5"
            paddingLeft={2}
            paddingRight={2}
            fontSize={theme.dimensions.headerFontSize}
            target="_blank"
          >
            Voorwaarden
          </Link>
        </Stack>
      </Box>

      {/* <Box
        id="Footer"
        height={
          smallDevice
            ? theme.dimensions.heightAppBar + 40
            : theme.dimensions.heightAppBar
        }
        width={"100%"}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
        }}
        bgcolor={theme.appColors.primaryLight}
      >
        <Stack
          id="innerFooter"
          direction="row"
          justifyContent="space-between"
          maxWidth={1600}
          width="85%"
        >
          <Box>
            <Typography
              variant="subtitle1"
              fontWeight={700}
              color={theme.appColors.white}
            >
              CONTACT
            </Typography>
            <Stack
              direction={smallDevice ? "column" : "row"}
              spacing={1}
              alignItems={smallDevice ? undefined : "center"}
            >
              <IconButton
                sx={{ padding: { xs: 0 } }}
                size="small"
                onClick={(e) => {
                  //@ts-ignore
                  window.location = `mailto:  info@vcaexamen.nl?subject=Bericht vanaf de website VCAexamen.nl`;
                  e.preventDefault();
                }}
                style={{ backgroundColor: "transparent" }}
                color="inherit"
              >
                <Email
                  sx={{
                    color: theme.appColors.white,
                    height: 20,
                  }}
                />
                <Typography
                  color={theme.appColors.tertiaryLight}
                  fontSize={theme.dimensions.headerFontSize}
                >
                  info@vcaexamen.nl
                </Typography>
              </IconButton>
              {!smallDevice && (
                <Typography color={theme.appColors.tertiaryLight}>|</Typography>
              )}
               <IconButton
                    onClick={(e) => {
                      //@ts-ignore
                      window.location = `tel:31850705318`;
                      e.preventDefault();
                    }}
                    size="small"
                    style={{ backgroundColor: "transparent" }}
                    color="inherit"
                  >
                    <Phone
                      sx={{
                        color: theme.appColors.white,
                        height: 20,
                      }}
                    />
                    {!smallDevice && (
                      <Typography
                        color={theme.appColors.white}
                        fontSize={theme.dimensions.headerFontSize}
                      >
                        085 - 0705318
                      </Typography>
                    )}
                  </IconButton>
            </Stack>
          </Box>
          <IdealLogo height="67px" width="80px" />
        </Stack>
      </Box>
      <Box
        bgcolor={theme.appColors.primaryLight}
        id="Footer2"
        width={"100%"}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Stack
          id="innerFooter2"
          direction={{ md: "row", xs: "column" }}
          maxWidth={1600}
          width="85%"
          paddingTop={2}
          paddingBottom={2}
          paddingRight={2}
        >
          <Link
            href="https://VCAexamen.nl"
            variant="inherit"
            underline="hover"
            color={theme.appColors.tertiaryLight}
            lineHeight="1.5"
            paddingRight={2}
            fontSize={theme.dimensions.headerFontSize}
          >
            © {dayjs().format("YYYY")} VCAexamen.nl
          </Link>
          <Divider
            orientation={smallDevice ? "horizontal" : "vertical"}
            flexItem
          />

          <Link
            href="https://vcaexamen.nl/informatie/algemene-voorwaarden/"
            variant="inherit"
            underline="hover"
            color={theme.appColors.tertiaryLight}
            lineHeight="1.5"
            paddingLeft={2}
            paddingRight={2}
            fontSize={theme.dimensions.headerFontSize}
            target="_blank"
          >
            Voorwaarden
          </Link>
        </Stack>
      </Box> */}
    </Stack>
  );
};
